import React, {Component, useState, Fragment, useEffect} from 'react';
import {Route, Link} from 'react-router-dom';
import ezglogo from '../../assets/ezg_logo.png';
import eclogo from '../../assets/ec_logo.png';
import Card from "react-bootstrap/Card";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Project = (props) => {

    // 50 - EZG
    // 71 - Wien
    // 72 - Rellingen
    // 73 - Bartels
    // 74 - Frankfurt
    var projectIcon;
    var projectIPath;


    if (props.projectID === "50") {
        projectIPath = '/devices';
        projectIcon = ezglogo;
    }

    if (props.projectID === "71") {
        projectIPath = '/econtrol';
        projectIcon = eclogo;
    }

    if (props.projectID === "72") {
        projectIPath = '/econtrol2';
        projectIcon = eclogo;
    }

    if (props.projectID === "73") {
        projectIPath = '/econtrol3';
        projectIcon = eclogo;
    }

    if (props.projectID === "74") {
        projectIPath = '/econtrol4';
        projectIcon = eclogo;
    }

    if (props.projectID === "76") {
        projectIPath = '/econtrol5';
        projectIcon = eclogo;
    }

    if (props.projectID === "77") {
        projectIPath = '/econtrol6';
        projectIcon = eclogo;
    }

    return (
        <div className="card projectCard">          
            <Card.Header className="text-muted">
                <div className="card-project-footer-text">{props.projectCompany}</div>
                {/* <div className="card-project-toolbox"><EditOutlinedIcon/></div> */}
            </Card.Header>   
            <div className="card-body">
                {/* <Route path="/Devices/" component={props=><Devices id={props.ProjectId}/>}></Route> */}
                <Link to={{pathname: projectIPath}}>
                    <Row>
                        <Col sm="true"><img className="card-project-logo" src={projectIcon} alt="Logo"/></Col>
                        <Col sm="true" xs="true"><div className="card-project-title">{props.projectName}</div></Col>
                    </Row> 
                </Link>
            </div>             
        </div>
    );
};

export default Project;